<template>
  <div class="projects">
    <div class="container flex justify-center content-center direction-column h-100">
      <PageTitle :title="'Projekty'"/>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <Header :title="'Stworzone realizacje'"/>
    </div>
    <div class="container flex justify-center vertical-center direction-column--md">
      <div class="hexagon__container" v-for="project in projects" :key="project">
        <div class="hexagon">
          <h3 class="hexagon__title">{{ project.title }}</h3>
          <div class="btn__container">
            <button type="button"
                    class="btn btn--primary"
                    @click="openDetails(project)">
              Zobacz szczegóły
            </button>
          </div>
          <img v-if="project.icon" :src="project.icon" class="hexagon__img" />
        </div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <ProjectDetails
      :title="openProject.title"
      :description="openProject.description"
      :img="openProject.img"
      :cost="openProject.cost"
      :difficulty="openProject.difficulty"
      :workTime="openProject.workTime"
      v-if="isOpen"
      v-on:clickBtn="closeDetails"/>
  </transition>
</template>

<script>
import { onMounted, ref } from 'vue';
import store from '../store';
import Header from '../components/Header';
import PageTitle from '../components/PageTitle';
import ProjectDetails from '../components/ProjectDetails';

export default {
  name: 'Projects',
  components: { Header, PageTitle, ProjectDetails },
  setup() {
    const projects = ref([]);
    const isOpen = ref(false);
    const openProject = ref({});

    const openDetails = async (item) => {
      const project = await store.dispatch('getProject', item.id);
      isOpen.value = true;
      openProject.value.title = project.title;
      openProject.value.description = project.description;
      openProject.value.img = project.img;
      openProject.value.cost = project.budget;
      openProject.value.difficulty = project.difficulty;
      openProject.value.workTime = project.workTime;
      document.body.classList.add('overflow-hidden');
    };

    const closeDetails = () => {
      isOpen.value = false;
      document.body.classList.remove('overflow-hidden');
    };

    onMounted(async () => {
      document.title = 'Projekty - adamkanigowski.pl';
      await store.dispatch('getProjects');
      projects.value = store.getters.projects;
    });

    return {
      projects, isOpen, openProject, openDetails, closeDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars';

.projects {
  background: url('../assets/hero_project.jpg') no-repeat;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: $padding-4;

  @media #{$lg-min}  {
    background-position-y: -200px;
  }

  &__img {
    fill: $background-color;
    width: auto;
    height: 6rem;
    margin: -3rem 0 0 -3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: .05;
    z-index: 0;

    @media #{$md-min} {
      height: 10rem;
      margin: -5rem 0 0 -5rem;
    }
  }
}
</style>
